import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { number } from "zod";

interface iSideBar {
  sidebarHidden: boolean;
  sidebarExpanded: boolean;
  sidebarChats: boolean;
  titleTab: string;
}
const initialState: iSideBar = {
  sidebarHidden: true,
  sidebarExpanded: false,
  sidebarChats: false,
  titleTab: ""
};
const sidebarInteractionsSlice = createSlice({
  name: "sidebarInteractions",
  initialState,
  reducers: {
    toggleShowSidebar(state) {
      state.sidebarHidden = !state.sidebarHidden;
    },
    toggleSidebarExpanded(state) {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
    setSidebarExpanded(state, action: PayloadAction<boolean>) {
      state.sidebarExpanded = action.payload;
    },
    setSidebarChats(state, action: PayloadAction<boolean>) {
      state.sidebarChats = action.payload;
    },
    setTitleTab(state, action: PayloadAction<string>) {
      state.titleTab = action.payload;
    }
  }
});

export const {
  toggleShowSidebar,
  toggleSidebarExpanded,
  setSidebarExpanded,
  setSidebarChats,
  setTitleTab
} = sidebarInteractionsSlice.actions;

export default sidebarInteractionsSlice.reducer;
